
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$IMPEX-PORTAL-primary: mat-palette($mat-indigo);
$IMPEX-PORTAL-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$IMPEX-PORTAL-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$IMPEX-PORTAL-theme: mat-light-theme((
  color: (
    primary: $IMPEX-PORTAL-primary,
    accent: $IMPEX-PORTAL-accent,
    warn: $IMPEX-PORTAL-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($IMPEX-PORTAL-theme);

/* You can add global styles to this file, and also import other style files */
@import "~font-awesome/scss/font-awesome.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@import "~ngx-toastr/toastr";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

/*****************************************************
	Custom styling example bellow.
*****************************************************/
// :root {
//     font-size: calc(1vw + 1vh + 0.5vmin);
// }
body {
    position: relative;
    background: #bfbfbf26;
    font-family: 'Open Sans', sans-serif;
}
.iti {
    display: block !important;
}

.iti .dropdown-menu.country-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #c7cace;
    margin-top: -1px;
}

.iti .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 244px;
    max-height: 170px;
}

.iti__flag-container.open + input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.iti .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
}

.iti .search-container input:focus {
    outline: none;
}

@media screen and (max-width: 479px) {
    .iti .iti__country-list {
        width: 88.3vw;
    }
}

ngx-intl-tel-input input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
}

ngx-intl-tel-input input:focus {
    outline: none !important;
    border-color: #3498db;
    box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
    background-color: #e5eaf1;
}

.label {
    font-weight: 500;
    color: #4d4d4d;
    margin-bottom: 0.5rem;
}

.form-group {
    margin-bottom: 1rem;
}
.label {
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .wrapper {
        margin: 2rem 0rem !important;
    }
}

.text-blue{
    color: #1981CF;
}
.text-light-blue{
    color: #63B5FF;
}
.bg-white{
    background: #FFFFFF;
}
.bg-blue{
    background-color: #E1FEFF;
}
.text-light-black{
    color: #4D4D4D;
}
.font-14{
    font-size: 14px;
}
.padding-left-50{
    padding-left: 50px;
}
.bg-light-green{
    background-color: #F5FDFF;
}
html, body { height: 100%; }
body { margin: 0;}
.mat-form-field-wrapper{
    padding-bottom: 0;
}
.mat-form-field-underline{
    display: none;
}
.mat-form-field-appearance-fill .mat-form-field-flex{
    background-color: transparent !important;
}

.filters-select{
    margin: 0 15px;
    @media screen and (min-width:1200px) and(max-width:1440px){  
        margin: 0 5px;
    }
    .mat-form-field-wrapper{
        height: 50px;
        background-color: #f5fdff;
        font-weight: 600;
        min-width: 330px;
        border: 1px solid #ced4da;
        border-radius: 5px;
        @media screen and (min-width:1200px) and(max-width:1365px){  
            min-width: 220px;
        }
        @media screen and (min-width:1366px) and(max-width:1440px){  
            min-width: 250px;
        }
        .mat-select-min-line{
            color: #1981CF;
            font-size: 14px;
            font-weight: 500;
        }
        .mat-select-arrow-wrapper{
            .mat-select-arrow{
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 11px solid;
            }
        }
    }
}
.custom-container{
    padding: 0 65px;
}
.h3{
    font-size: 22px !important;
    font-weight: 500 !important;
    color: #1A1A1A;
}
.h4{
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #1A1A1A;
    
}
.wrap-radius{
    border-radius: 4px;
}
.step-wraper{
    .mat-step-header{
        .mat-step-icon{
            background-color: #fff !important;
            border: 1px solid #BFBFBF !important;
        }
        &:hover{
            background: transparent;
        }
        &:focus{
            background: transparent;
        }
        .mat-step-icon-selected{
            background-color: #1981CF !important;
            border: 1px solid #1981CF !important;
            overflow: hidden;
            .mat-step-icon-content{
                .ng-star-inserted{
                    text-indent: 40px;
                    display: inline-block;
                    overflow: hidden;
                    &::after{
                        content: "\f00c";
                        font-family: 'FontAwesome';
                        position: absolute;
                        top: 10px;
                        left: 8%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        } 
    }
}
.padding-top-bottom{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.deals-horizontal-steps{
    .mat-horizontal-stepper-header-container{
        .mat-step-header{
            &:hover{
                background-color: transparent;
            }
            &:focus{
                background: transparent;
            }
            display: block;
            .mat-step-icon{
                background-color: #fff !important;
                border: 1px solid #BFBFBF !important;
            }
            .mat-step-icon-selected{
                background-color: #1981CF !important;
                border: 1px solid #1981CF !important;
                overflow: hidden;
                .mat-step-icon-content{
                    .ng-star-inserted{
                        text-indent: 40px;
                        display: inline-block;
                        overflow: hidden;
                        &::after{
                            content: "\f00c";
                            font-family: 'FontAwesome';
                            position: absolute;
                            top: 10px;
                            left: 8%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
            .mat-step-icon-state-error{
                color: transparent;
                &::after{
                    content: "\f00d";
                    font-family: 'FontAwesome';
                    position: absolute;
                    top: 10px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #f44336;
                    font-size: 20px;
                }
            } 
        }
    }
}